import React, { useState, useEffect } from "react"
import Hero from "./Hero"
import { Box } from "@mui/system"
import Head from "../utils/Head"
import Clutch from "../Homepage/Clutch"
const UsersWorldwide = React.lazy(() => import("./UsersWorldwide"))
const LeadershipTeam = React.lazy(() =>
  import("./LeadershipTeam/LeadershipTeam")
)
const ImageGallery = React.lazy(() => import("./ImageGallery/ImageGallery"))
const CoreValues = React.lazy(() => import("./CoreValues/CoreValues"))
const Reviews = React.lazy(() => import("../../components/Homepage/Reviews"))
const ContactUs = React.lazy(() => import("../shared/Footer/ContactUs"))

function AboutUs() {
  const [showReview, setShowReview] = useState(false)
  useEffect(() => {
    setShowReview(true)
  }, [])
  return (
    <div>
      <Head
        metaTitle="About Us - CodeFulcrum"
        title="Meet CodeFulcrum: Your Software Development Partner"
        desc="Our custom software development services can help you in your digital transformation journey. We have a team of experienced professionals who can help you design, build, and maintain your data infrastructure."
      />
      <Hero
        btnText="LET'S DISCUSS HOW"
        header="The team behind your digital transformation"
        scroll="#users"
      />
      <React.Suspense fallback={<div />}>
        <UsersWorldwide />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <LeadershipTeam />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ImageGallery />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <CoreValues />
      </React.Suspense>
      {/* {showReview && (
        <React.Suspense fallback={<div />}>
          <Reviews />
        </React.Suspense>
      )} */}

      <React.Suspense fallback={<div />}>
        <Clutch />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <Box>
          <ContactUs />
        </Box>
      </React.Suspense>
    </div>
  )
}

export default AboutUs
