import React from "react"
import AboutUs from "../components/AboutUs"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

export default function ServicesPage() {
 
  
  
 
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/about-us/" />
      </Helmet>
      <AboutUs />
      
    </Layout>
  )
}
