import React from "react"
function HeroWireDataEng() {
  return (
    <img src="/productStrategy/hero-wire.svg" alt="hero-wire" loading="lazy" />
  )
}
function StreamApp() {
  return (
    <img
      src="/dataEngineering/stream-app.svg"
      alt="stream-app"
      loading="lazy"
    />
  )
}

function BigData() {
  return (
    <img src="/dataEngineering/big-data.svg" alt="big-data" loading="lazy" />
  )
}
function DataInt() {
  return (
    <img src="/dataEngineering/data-int.svg" alt="data-int" loading="lazy" />
  )
}
function DataVisual() {
  return (
    <img
      src="/dataEngineering/data-visual.svg"
      alt="data-visual"
      loading="lazy"
    />
  )
}
function StatData() {
  return (
    <img src="/dataEngineering/stat-data.svg" alt="stat-data" loading="lazy" />
  )
}
function Reporting() {
  return (
    <img src="/dataEngineering/reporting.svg" alt="reporting" loading="lazy" />
  )
}
function ProductStrategy() {
  return (
    <img
      src="/productStrategy/productStrategy.svg"
      alt="product-design"
      loading="lazy"
    />
  )
}
function ProductDesign() {
  return (
    <img
      src="/productStrategy/productDesign.svg"
      alt="product-design"
      loading="lazy"
    />
  )
}
function SoftwareDevelopment() {
  return (
    <img
      src="/productStrategy/softwareDevelopment.svg"
      alt="software-development"
      loading="lazy"
    />
  )
}

function TrackHero() {
  return (
    <img
      src="/aboutUs/projects/trackhero.svg"
      style={{ height: "45px" }}
      alt="track-hero"
      loading="lazy"
    />
  )
}
function OhmConnect() {
  return (
    <img
      src="/aboutUs/projects/ohmconnect.svg"
      style={{ height: "45px" }}
      alt="ohm-connect"
      loading="lazy"
    />
  )
}
function Strada() {
  return (
    <img
      src="/aboutUs/projects/strada.svg"
      style={{ height: "45px" }}
      alt="strada-project"
      loading="lazy"
    />
  )
}
function Pixeleto() {
  return (
    <img
      src="/aboutUs/projects/pixeleto.svg"
      style={{ height: "45px" }}
      alt="pixeleto-project"
      loading="lazy"
    />
  )
}

function Vectorscient() {
  return (
    <img
      src="/aboutUs/projects/vector.svg"
      style={{ height: "45px" }}
      alt="vector-project"
      loading="lazy"
    />
  )
}
function DriverBee() {
  return (
    <img
      src="/aboutUs/projects/driverbee.svg"
      style={{ height: "45px" }}
      alt="driver-project"
      loading="lazy"
    />
  )
}
function Aimexperts() {
  return (
    <img
      src="/aboutUs/projects/aimexpert.svg"
      style={{ height: "45px" }}
      alt="aimexpert-project"
      loading="lazy"
    />
  )
}
function IBD() {
  return (
    <img
      src="/aboutUs/projects/IBD.svg"
      style={{ height: "45px" }}
      alt="ibd-project"
      loading="lazy"
    />
  )
}
function Ugami() {
  return (
    <img
      src="/aboutUs/projects/ugami.svg"
      style={{ height: "45px" }}
      alt="ugami-project"
      loading="lazy"
    />
  )
}
function Hero() {
  return (
    <img
      src="/aboutUs/projects/hero.svg"
      style={{ height: "45px" }}
      alt="hero-project"
      loading="lazy"
    />
  )
}
function Rating() {
  return (
    <img
      src="/aboutUs/projects/rating.svg"
      style={{ height: "45px" }}
      alt="rating-project"
      loading="lazy"
    />
  )
}
function Energy() {
  return (
    <img
      src="/aboutUs/projects/energy.svg"
      style={{ height: "45px" }}
      alt="energy-project"
      loading="lazy"
    />
  )
}

function Hammad() {
  return (
    <img src="/aboutUs/pictures/hammad.png" alt="about-hammad" loading="lazy" />
  )
}
function GrayBackground() {
  return (
    <img
      src="/aboutUs/pictures/hammad.png"
      alt="about-hammad-gray"
      loading="lazy"
    />
  )
}
function HammadHover() {
  return (
    <img
      src="/aboutUs/pictures/hammad-hover.png"
      alt="hammad-hover"
      loading="lazy"
    />
  )
}
function Hamza() {
  return (
    <img src="/aboutUs/pictures/hamza.png" alt="about-hamza" loading="lazy" />
  )
}
function HamzaHover() {
  return (
    <img
      src="/aboutUs/pictures/hamza-hover.png"
      alt="about-hamza-hover"
      loading="lazy"
    />
  )
}
function Faizan() {
  return (
    <img src="/aboutUs/pictures/faizan.png" alt="about-faizan" loading="lazy" />
  )
}
function FaizanHover() {
  return (
    <img
      src="/aboutUs/pictures/faizan-hover.png"
      alt="faizan-hover"
      loading="lazy"
    />
  )
}
function Mueeza() {
  return (
    <img src="/aboutUs/pictures/mueeza.png" alt="about-mueeza" loading="lazy" />
  )
}
function MueezaHover() {
  return (
    <img
      src="/aboutUs/pictures/mueeza-hover.png"
      alt="mueeza-hover"
      loading="lazy"
    />
  )
}
function Ihtram() {
  return (
    <img
      src="/aboutUs/pictures/ihtram.png"
      alt="about-ihteram"
      loading="lazy"
    />
  )
}
function IhtramHover() {
  return (
    <img
      src="/aboutUs/pictures/ihtram-hover.png"
      alt="ihtram-hover"
      loading="lazy"
    />
  )
}

function Sikander() {
  return (
    <img
      src="/aboutUs/pictures/sikandar.png"
      alt="about-sikandar"
      loading="lazy"
    />
  )
}
function HamzaKhalid() {
  return (
    <img
      src="/aboutUs/pictures/hamzakhalid.png"
      alt="about-sikandar"
      loading="lazy"
    />
  )
}
function HamzaKhalidHover() {
  return (
    <img
      src="/aboutUs/pictures/hamzakhalid-hover.png"
      alt="about-sikandar"
      loading="lazy"
    />
  )
}
function SikanderHover() {
  return (
    <img
      src="/aboutUs/pictures/sikandar-hover.png"
      alt="sikandar-hover"
      loading="lazy"
    />
  )
}
export function CraftsmanshipIcon() {
  return <img src="/aboutUs/icons/img-6.png" alt="craftman" loading="lazy" />
}
export function TransparencyIcon() {
  return (
    <img src="/aboutUs/icons/img-4.png" alt="transparency" loading="lazy" />
  )
}
export function DecisivenessIcon() {
  return (
    <img src="/aboutUs/icons/img-2.png" alt="decisiveness" loading="lazy" />
  )
}
export function SolidarityIcon() {
  return <img src="/aboutUs/icons/img-3.png" alt="solidarity" loading="lazy" />
}
export function EmpathyIcon() {
  return <img src="/aboutUs/icons/img-5.png" alt="empathay" loading="lazy" />
}
export function ValueCentricityIcon() {
  return <img src="/aboutUs/icons/img-1.png" alt="centricity" loading="lazy" />
}

export {
  HeroWireDataEng,
  StreamApp,
  BigData,
  DataInt,
  DataVisual,
  StatData,
  Reporting,
  ProductStrategy,
  ProductDesign,
  SoftwareDevelopment,
  TrackHero,
  OhmConnect,
  Strada,
  Pixeleto,
  Vectorscient,
  DriverBee,
  Aimexperts,
  IBD,
  Ugami,
  Hero,
  Rating,
  Energy,
  Hammad,
  Hamza,
  Faizan,
  Mueeza,
  Ihtram,
  Sikander,
  HammadHover,
  HamzaHover,
  SikanderHover,
  IhtramHover,
  MueezaHover,
  FaizanHover,
  GrayBackground,
  HamzaKhalid,
  HamzaKhalidHover,
}
